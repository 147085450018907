import { useState } from 'react'

import { Heading3, Button } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import StyledModal from '~/components/modals/StyledModal'
import { EventClick, EventScreenName } from '~/events/eventTypes'
import useTrackClick from '~/events/trackers/useTrackClick'
import useTrackScreenView from '~/events/trackers/useTrackScreenView'
import { useLogout } from '~/hooks/useAuth'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import logger from '~/utils/logger'

interface LogoutModalProps {
  onClose: () => void
}

const LogoutModal: React.FC<LogoutModalProps> = ({ onClose }) => {
  const { processOryLogout } = useLogout()
  const t = useTranslationForNamespace('common')
  useTrackScreenView({ screen_name: EventScreenName.LogOut })
  const { trackCta } = useTrackClick()
  const [loading, setLoading] = useState(false)

  const onLogout = async () => {
    try {
      setLoading(true)
      await processOryLogout()
      onClose()
    } catch (error) {
      // ideally processOryLogout code should not fail.
      logger.log('error logging out: ', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal isOpen={true}>
      <Heading3>{t('notifications.logoutConfirmation')}</Heading3>
      <LogoutActionContainer>
        <Button
          disabled={loading}
          onClick={() => {
            trackCta({
              click_name: EventClick.names.LogOutConfirm,
              text: t('buttons.confirmLogout'),
              type: EventClick.types.Button,
              destination: EventScreenName.LogOut,
            })
            onLogout()
          }}
        >
          {loading ? t('loadingWithEllipses') : t('buttons.confirmLogout')}
        </Button>
        <Button
          kind='secondary'
          disabled={loading}
          onClick={() => {
            trackCta({
              click_name: EventClick.names.LogOutCancel,
              text: t('buttons.neverMind'),
              type: EventClick.types.Button,
              destination: EventScreenName.LogOut,
            })
            onClose()
          }}
        >
          {t('buttons.neverMind')}
        </Button>
      </LogoutActionContainer>
    </Modal>
  )
}

const LogoutActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  margin-top: 32px;
  z-index: 1;
`

const Modal = styled(StyledModal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: min(100%, 592px);
  padding: 48px 72px;
`

export default LogoutModal
