import { GetTodaysWorkoutQuery } from '~/graphql/generated/schema'

export const mapWorkout = (workout: { id: any; slots: any; createdAt?: any }, hasPremium = true): Workout => {
  // Most uses of this function need the entire workout so Premium is the default, for getNextUnplayedGame we'll use the correct sub status
  const slots = hasPremium
    ? {
        slot1: workout.slots.slot1,
        slot2: workout.slots.slot2,
        slot3: workout.slots.slot3,
        slot4: workout.slots.slot4,
        slot5: workout.slots.slot5,
      }
    : {
        slot1: workout.slots.slot1,
        slot2: workout.slots.slot2,
        slot5: workout.slots.slot5,
      }

  const mappedWorkout: Workout = {
    id: workout.id,
    createdAt: workout.createdAt,
    slots: slots,
  }

  return mappedWorkout
}

/**
 * getWorkoutMeta will be 1 place to extract out all useful information from todaysWorkout.
 *
 * TODO: In this file, in multiple functions, we are looping todaysWorkout, to extract different kind of information from same data
 * example : getCompletedGameSlugs, getTotalGamesPlayed, getNextUnplayedGame
 * TODO: Remove all redundant functions & adjust the code.
 * This function should loop todaysWorkout 1 time & should extract all necessary info at once
 */
export const getWorkoutMeta = ({
  queryResult,
  hasPremium,
}: {
  queryResult?: GetTodaysWorkoutQuery
  hasPremium: boolean
}) => {
  const workoutData = queryResult?.me?.todaysWorkout
  if (!workoutData) return null

  const { slots } = mapWorkout(workoutData, hasPremium)
  const allSlots = Object.values(slots)
  const allSlugs: Array<string> = []

  const lastPlayedGames: WorkoutGame[] = []
  const nextUnplayedGames: WorkoutGame[] = []

  // pivotBySlug is quick access for mapping slug to the slot
  const pivotBySlug: Record<string, WorkoutGame & { positionNumber: number }> = {}

  allSlots.forEach((slot, index) => {
    allSlugs.push(slot.game.slug)
    // positionNumber is 1 based, used to show the position of the game by the slug
    pivotBySlug[slot.game.slug] = { ...slot, positionNumber: index + 1 }
    if (slot.lastGamePlay) {
      lastPlayedGames.push(slot)
    }
    if (!slot.lastGamePlay) {
      nextUnplayedGames.push(slot)
    }
  })

  const totalGamesCount = allSlots.length
  const totalGamePlayed = lastPlayedGames.length
  // Prevent the remaining game count going below 0 when the sub status goes from Premium to Free
  const _remainingGameCount = totalGamesCount - totalGamePlayed
  const remainingGameCount = _remainingGameCount < 0 ? 0 : _remainingGameCount

  // allPremiumSlots is an array of all slots in the workout, irrespective of hasPremium, i,e  5 slots.
  // used in rendering donut
  const allPremiumSlots = [
    workoutData.slots.slot1,
    workoutData.slots.slot2,
    workoutData.slots.slot3,
    workoutData.slots.slot4,
    workoutData.slots.slot5,
  ]

  return {
    allSlugs,
    totalGamePlayed,
    isFirstGame: totalGamePlayed === 0,
    isLastGame: totalGamePlayed === totalGamesCount - 1,
    isWorkoutStarted: totalGamePlayed > 0,
    isWorkoutComplete: totalGamePlayed === totalGamesCount,

    nextUnplayedGame: nextUnplayedGames[0],
    nextUnplayedGames,

    lastPlayedGame: lastPlayedGames[lastPlayedGames.length - 1],
    lastPlayedGames,

    algorithmVersion: workoutData.algorithmVersion || '',
    workoutId: workoutData.id ?? '',
    totalGamesCount,
    remainingGameCount,
    pivotBySlug,
    allSlots,

    allPremiumSlots,
  }
}
export type WorkoutMetaNotNull = Exclude<ReturnType<typeof getWorkoutMeta>, null>
