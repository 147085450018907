import { useContext, useMemo } from 'react'

import { PlayContext } from '~/constants'
import { AuthContext } from '~/context/AuthContext'
import { GameContext } from '~/context/GameContext'
import { MarketingContext } from '~/context/MarketingContext'
import { useGetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import { useAvailableGames } from '~/hooks/useAvailableGames'
import { getWorkoutMeta } from '~/utils/workoutUtils'

type UseTodaysWorkoutOptions = {
  skip?: boolean
}

const useTodaysWorkout = ({ skip }: UseTodaysWorkoutOptions = { skip: false }) => {
  const availableGames = useAvailableGames()
  const { hasPremium } = useContext(AuthContext)
  const { playContext } = useContext(GameContext)
  const { logMarketingWorkoutComplete } = useContext(MarketingContext)

  const { data, loading, error } = useGetTodaysWorkoutQuery({
    variables: { knownGameSlugs: availableGames.map(({ gameSlug }) => gameSlug) },
    skip,
    onCompleted: (queryResult) => {
      if (playContext === PlayContext.Workout) {
        const _workoutMeta = getWorkoutMeta({ queryResult, hasPremium })
        if (_workoutMeta) {
          const { isWorkoutComplete, allSlugs } = _workoutMeta
          if (isWorkoutComplete) {
            logMarketingWorkoutComplete(allSlugs as GameSlug[])
          }
        }
      }
    },
  })

  const workoutMeta = useMemo(() => getWorkoutMeta({ queryResult: data, hasPremium }), [data, hasPremium])

  // do not return raw data here, instead return the extracted data i.e. workoutMeta
  return { loading, error, workoutMeta }
}

export default useTodaysWorkout
