import { useMemo } from 'react'

import useTranslation from 'next-translate/useTranslation'

import GamesLibrary, { allVersionedGames } from '~/gameConfigs/GamesLibrary'

export const useAvailableGames = () => {
  const { lang } = useTranslation()

  const filteredGameSlugs = useMemo(() => {
    const gameSlugsWithLanguageFilter =
      lang === 'en'
        ? allVersionedGames
        : GamesLibrary.filter((game) => !game.isEnglishOnly).map((game) => ({
            gameSlug: game.slug,
            gameVersion: game.version,
          }))

    return gameSlugsWithLanguageFilter
  }, [lang])

  return filteredGameSlugs
}
